import React, { useEffect, useRef } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import uaParser from 'ua-parser-js';
import parseISO from 'date-fns/parseISO';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { REDUX_STATUS } from 'lib/constants';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import { FACILITATOR_ENGAGEMENTS_SHOW_PATH, FACILITATOR_MESSAGES_NEW_PATH, FACILITATOR_PARTICIPANTS_CONVERSATION_PATH, FACILITATOR_PARTICIPANTS_MESSAGES_PATH, FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import { RESET_USER } from 'store/user/actions';
import { LOAD_GROUP_MEMBERSHIP, RESET_GROUP_MEMBERSHIPS } from 'store/groupMemberships/actions';
import { RESET_LETTER_MESSAGE } from 'store/indigenousWelcomeLetters/actions';
import RBSpinner from 'react-bootstrap/Spinner';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import TabbedNav from 'components/shared/Navigation/TabbedNav';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import Spinner from 'components/shared/Spinner';
import SanyasIcon from 'components/shared/SanyasIcon';
import ParticipantBadge from 'components/shared/Badge/ParticipantBadge';
import Skeleton from 'components/shared/Skeleton';
import ErrorMessage from 'components/shared/ErrorMessage';
import { canSendMessage } from 'components/shared/Messages/utils';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import ProfileTabPage from './ProfileTabPage';
import MessagesTabPage from './MessagesTabPage';
import EngagementsShowPage from './EngagementsShowPage';

function ParticipantsShowPage() {
  const { groupMembershipId } = useParams();
  const currentUser = useSelector((state) => state.currentUser);
  const user = useSelector((state) => state.user);
  const groupMemberships = useSelector((state) => state.groupMemberships);
  const dispatch = useDispatch();
  const headerRef = useRef(null);

  const groupMembership = groupMemberships.data?.[0];
  const { hasMessageAccess } = canSendMessage({ currentUserId: currentUser?.id, groupMembership });
  const { browser, os } = !!groupMembership?.userAgent && uaParser(groupMembership.userAgent);

  useEffect(() => {
    dispatch(LOAD_GROUP_MEMBERSHIP.request({ id: groupMembershipId }));
  }, [groupMembershipId, dispatch]);

  useEffect(() => () => {
    dispatch(RESET_USER.action());
    dispatch(RESET_GROUP_MEMBERSHIPS.action());
    dispatch(RESET_LETTER_MESSAGE.action());
  }, [dispatch]);

  useDocumentTitle(user.data?.fullName || 'Participant');

  return (
    <FacilitatorTemplate>
      {groupMemberships.error ? (
        <ErrorMessage error={groupMemberships.error} className="my-4" />
      ) : (
        <div className="d-flex flex-column flush">
          <div ref={headerRef} className="header pb-0">
            <div className="d-flex align-items-center flex-col-gap-sm">
              <SanyasIcon name="person" size="3x" />
              <div>
                <Breadcrumbs
                  navItems={[
                    { label: 'Participants' },
                    { label: user.data?.fullName ?? <Skeleton classes="text" /> },
                  ]}
                />

                <div className="d-flex align-items-center mb-2">
                  <h1 className="mb-0">
                    { user.data?.fullName ?? <Skeleton classes="title" /> }
                  </h1>

                  {(user.status === REDUX_STATUS.PENDING || groupMemberships.status === REDUX_STATUS.PENDING) && (
                    <RBSpinner variant="primary" size="sm" className="ms-1" animation="border" role="status" />
                  )}

                  {groupMembership?.flag && <span><SanyasIcon className="text-danger ms-2" name="flagFill" size="md" /></span>}

                  <span className="ms-2 text-small">
                    <ParticipantBadge
                      state={groupMembership?.completion}
                      completion={groupMembership?.decoratedCompletion}
                      activation={user.data?.activation}
                    />
                    <span className="ms-2">
                      {!!groupMembership?.lastSeenAt && `Last seen ${formatDistanceToNow(parseISO(groupMembership.lastSeenAt))} ago |`}
                      {(browser && os) && ` ${browser.name} ${browser.version}, ${os.name} ${os.version}`}
                    </span>
                  </span>
                </div>
              </div>
              {hasMessageAccess && (
                <div className="ms-auto">
                  <SimpleToolTip text={`Open a new tab/window and send a message to ${user.data?.firstName || 'this participant'}`}>
                    <a
                      href={`${FACILITATOR_MESSAGES_NEW_PATH}?user_id=${user.data?.id}`}
                      className="btn btn-outline-primary btn-sm text-nowrap"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SanyasIcon name="boxArrowUpRight" className="me-1" />
                      Send message
                    </a>
                  </SimpleToolTip>
                </div>
              )}
            </div>

            <div className="position-relative">
              <TabbedNav
                variant="underline"
                navItems={[
                  {
                    title: 'Profile',
                    href: `/facilitator/participants/${groupMembershipId}`,
                    match: {
                      path: FACILITATOR_PARTICIPANTS_SHOW_PATH,
                      exact: true,
                    },
                  },
                  {
                    title: 'Messages',
                    href: `/facilitator/participants/${groupMembershipId}/messages`,
                    match: {
                      path: [FACILITATOR_PARTICIPANTS_MESSAGES_PATH, FACILITATOR_PARTICIPANTS_CONVERSATION_PATH],
                    },
                  },
                  {
                    title: 'Engagement',
                    href: `/facilitator/participants/${groupMembershipId}/engagements`,
                    match: {
                      path: FACILITATOR_ENGAGEMENTS_SHOW_PATH,
                      exact: true,
                    },
                  },
                ]}
              />
            </div>
          </div>

          <Switch>
            <Route path={[FACILITATOR_PARTICIPANTS_MESSAGES_PATH, FACILITATOR_PARTICIPANTS_CONVERSATION_PATH]}>
              <MessagesTabPage headerRef={headerRef} user={user?.data} gm={groupMembership} />
            </Route>
            <Route path={FACILITATOR_ENGAGEMENTS_SHOW_PATH}>
              <EngagementsShowPage />
            </Route>
            <Route path="*">
              {([groupMemberships.status, user.status].includes(REDUX_STATUS.PENDING) || !groupMembership)
                ? <div className="m-4"><Spinner /></div>
                : <ProfileTabPage user={user} gm={groupMembership} hasGroupMembership={groupMemberships.data?.length > 0} />}
            </Route>
          </Switch>
        </div>
      )}
    </FacilitatorTemplate>
  );
}

export default ParticipantsShowPage;
