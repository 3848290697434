import PreviewJournalAnswersTable from 'components/facilitator/PreviewJournalAnswersTable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { SHORT_DATE } from 'lib/dateFormats';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import { parseSubGroupName } from 'lib/utils';
import { LOAD_JOURNAL_ANSWERS } from 'store/facilitatorDashboard/actions';
import { fetchSubGroupData } from 'store/facilitatorDashboard/selectors';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import JournalHeader from 'components/facilitator/Journals/JournalHeader';
import NotesPanel from 'components/facilitator/NotesPanel';

function JournalsPreviewShowPage() {
  const params = useParams();
  const { subGroupId, taskId } = params;
  const subGroupData = useSelector((state) => fetchSubGroupData(state, subGroupId));
  const dispatch = useDispatch();
  const subGroupName = parseSubGroupName(subGroupData?.subGroupName);
  const journal = subGroupData?.journalNotifications[taskId];

  const [showNotesPanel, setShowNotesPanel] = useState(false);
  const handleClose = () => setShowNotesPanel(false);
  const toggleShow = () => setShowNotesPanel((prev) => !prev);

  useEffect(() => {
    dispatch(LOAD_JOURNAL_ANSWERS.request(params));
  }, [dispatch, params]);

  useDocumentTitle([
    `${journal?.name} (${journal?.internalName})`,
    subGroupName?.pod,
    `${subGroupData?.groupName} (${format(parseISO(subGroupData.groupBeginSessionAt), SHORT_DATE)})`,
  ]);

  return (
    <FacilitatorTemplate className="bg-light">
      <JournalHeader className="mb-4" toggleNotes={toggleShow} />
      <NotesPanel context="journalTask" show={showNotesPanel} onHide={handleClose} />

      <PreviewJournalAnswersTable />
    </FacilitatorTemplate>
  );
}

export default JournalsPreviewShowPage;
