import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO, subDays } from 'date-fns';
import { SHORT_DATE } from 'lib/dateFormats';
import { useDocumentTitle } from 'lib/hooks/useDocumentTitle';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_GROUP_SHOW_PATH } from 'lib/routerPaths';
import { parseSubGroupName } from 'lib/utils';
import { fetchSubGroupData } from 'store/facilitatorDashboard/selectors';
import { GET_SUB_GROUP_DATA } from 'store/facilitatorDashboard/actions';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import Skeleton from 'components/shared/Skeleton';
import Badge from 'components/shared/Badge/Badge';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import Prioritized from '../Prioritized';

function CommentsHeader({ className, toggleNotes }) {
  const { id, subGroupId, taskId } = useParams();
  const currentUser = useSelector((state) => state.currentUser);
  const { assignedFacilitatorId, commentIds, commentsById } = useSelector((state) => state.facilitatorDashboard);
  const subGroupData = useSelector((state) => fetchSubGroupData(state, subGroupId));
  const dispatch = useDispatch();

  const subGroupName = parseSubGroupName(subGroupData?.subGroupName);
  const discussionBoard = subGroupData?.discussionBoardNotifications[taskId];
  const unreadPosts = commentsById ? Object.values(commentsById).filter((comment) => !comment.notificationCleared).length : 0;
  const firstUnreadPost = commentsById ? Object.values(commentsById).find((comment) => !comment.notificationCleared) : null;
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId && subGroupData?.facilitatedTaskIds?.includes(Number(taskId));

  const uniqueComments = useMemo(() => {
    const groupMemberships = new Set();
    commentIds?.forEach((commentId) => {
      const comment = commentsById[commentId];
      if (!comment.isFacilitatorComment) groupMemberships.add(comment.groupMembershipId);
    });
    return groupMemberships.size;
  }, [commentIds, commentsById]);

  const recentPosts = useMemo(
    () => commentIds?.filter((commentId) => new Date(commentsById[commentId].createdAt) > subDays(new Date(), 2)),
    [commentIds, commentsById],
  );

  useEffect(() => {
    if (!subGroupData) {
      dispatch(GET_SUB_GROUP_DATA.request({ subGroupId }));
    }
  }, [subGroupId, subGroupData, dispatch]);

  useDocumentTitle([
    `${discussionBoard?.discussionBoardName} (${discussionBoard?.internalName})`,
    subGroupName?.pod,
    `${subGroupData?.groupName} (${format(parseISO(subGroupData.groupBeginSessionAt), SHORT_DATE)})`,
  ]);

  return (
    <div className={`flush sticky-top d-flex align-items-center bg-white border-bottom py-3 px-4 ${className || ''}`}>
      {!subGroupData && (
        <div className="w-100">
          <Skeleton classes="title width-quarter d-block mb-0" />
          <Skeleton classes="text width-quarter" />
        </div>
      )}

      {subGroupData && (
        <>
          <SanyasIcon name="discussion" size="xl" className="me-3 text-accent-purple" />
          <div className="d-flex flex-column">
            <div className="d-flex flex-wrap">
              <Link
                to={buildRoutePath(FACILITATOR_GROUP_SHOW_PATH, { id })}
                className="me-2 btn-plain"
              >
                <strong>{subGroupData.groupName}</strong>
              </Link>
              <div>
                <span className="text-small">{subGroupName.pod}</span>
                <span className="px-2 text-small">&middot;</span>
                <span className="text-small">
                  {format(parseISO(subGroupData.groupBeginSessionAt), SHORT_DATE)}
                  <span className="px-1">-</span>
                  {format(parseISO(subGroupData.groupCloseSessionAt), SHORT_DATE)}
                </span>
              </div>
            </div>
            <div>
              <Badge title={discussionBoard.internalName} variant={discussionBoard.internalName.toLowerCase()} className="me-2" />
              <span>{discussionBoard.discussionBoardName}</span>
            </div>
          </div>

          <div className="ms-4 border-start ps-4 text-small">
            <strong>{`${uniqueComments}/${subGroupData.participantCount}`}</strong>
            <br />
            responded
          </div>

          <div className="ms-4 border-start ps-4 text-small">
            <SimpleToolTip placement="bottom" text="New posts or replies in the last 2 days">
              <strong>{recentPosts?.length || 0}</strong>
              <br />
              {`recent post${recentPosts?.length !== 1 ? 's' : ''}`}
            </SimpleToolTip>
          </div>

          {isAssignedFacilitationPoint && (
            <div className="ms-4 border-start ps-4 text-small">
              <Link to={{ hash: `#${firstUnreadPost ? `comment-${firstUnreadPost?.id}` : 'reply-container'}` }} className="btn-plain text-black text-decoration-none">
                <strong>{unreadPosts}</strong>
                <br />
                {`unread post${unreadPosts !== 1 ? 's' : ''}`}
              </Link>
            </div>
          )}

          {subGroupName.facilitators && (
            <div className="ms-4 border-start ps-4 text-small">
              <strong>Facilitators</strong>
              <br />
              {subGroupName.facilitators}
            </div>
          )}

          {isAssignedFacilitationPoint && (
            <div className="ms-auto ps-4 d-flex flex-wrap gap-1">
              <Button variant="primary" size="sm" className="text-nowrap" onClick={() => window.scrollTo(0, document.body.scrollHeight)}>Reply to thread</Button>

              {!!toggleNotes && (
                <Button variant="outline-secondary" size="sm" onClick={toggleNotes}>
                  Edit notes
                </Button>
              )}
            </div>
          )}
          <div>
            <Prioritized
              note={discussionBoard.note || { subGroupId, taskId }}
              assigned={isAssignedFacilitationPoint}
              text={['Prioritize', 'Prioritized']}
              variant="plain"
              className="text-nowrap text-decoration-none"
              iconSize="lg"
            />
          </div>
        </>
      )}
    </div>
  );
}

CommentsHeader.defaultProps = {
  className: null,
  toggleNotes: null,
};

CommentsHeader.propTypes = {
  className: PropTypes.string,
  toggleNotes: PropTypes.func,
};

export default CommentsHeader;
